<template>
  <div :class="{closed: !opened}" accordion-list>
    <div class="title-wrap">
      <slot name="title"></slot>
      <button v-if="useAccordion" :class="{opened: opened}" class="arrow-btn" @click="opened = !opened">
        <svg-arrow :fill="'#787781'" />
      </button>
    </div>
    <ul class="list-wrap">
      <slot name="list"></slot>
    </ul>
  </div>
</template>

<script>
import SvgArrow from '@/views/graphics/svg-arrow.vue';

export default {
  name: 'AccordionList',
  components: { SvgArrow },
  props: {
    useAccordion: { type: Boolean, default: false },
  },
  data: () => ({
    opened: true,
  }),
  methods: {
    init() {
      const nodes = this.$el.childNodes;
      let targetNode;
      for (let i = 0; i < nodes.length; i++) {
        let flag = false;
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        nodes[i]?.classList?.forEach(c => {
          if (c === 'list-wrap') {
            targetNode = nodes[i];
            flag = true;
          }
        });
        if (flag) break;
      }
      if (targetNode) targetNode.style.maxHeight = `${targetNode.clientHeight}px`;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[accordion-list] { .pb(32); border-bottom: dashed 1px #d8d8d8; .c(#191919);
  .title-wrap { .rel; .flex; .items-center;
    > *:first-child { .w(100%); .m(0); }
    > h1 { .fs(28); .pb(28); border-bottom: solid 1px #000; }
    > h2, h3, h4, h5, h6 { .flex; .items-center; .h(60); .fs(16); }
    .arrow-btn { .abs; .r(0); background: none; border: none; transform: rotate(90deg); transition: transform 0.5s; .pointer;
      &.opened {transform: rotate(-90deg);}
    }
  }
  .list-wrap { .p(0); .m(0); overflow: hidden; transition: max-height 0.5s;
    > ul { list-style-type: none; .pl(12); .c(#787781); }
  }
  &.closed { .pb(0);
    .list-wrap { max-height: 0px !important; }
  }

}
</style>
