<template>
  <settlement-detail-layout coach-settlement-detail>
    <color-button type="light-gray" class="button-prev" @click="onBackward">
      <svg-angle />
    </color-button>
    <accordion-list>
      <template #title>
        <h1>정산 상세</h1>
      </template>
      <template #list>
        <accordion-list-item>
          <p>주문 번호</p>
          <p>{{ settlementInfo.settlementNumber }}</p>
        </accordion-list-item>
        <accordion-list-item>
          <p>입금일</p>
          <p>{{ settlementDatetime }}</p>
        </accordion-list-item>
        <accordion-list-item>
          <p>입금 상태</p>
          <status-badge-settlement :value="settlementInfo.status" />
        </accordion-list-item>
        <accordion-list-item>
          <em>실 입금 금액(A+B+C+D+E)</em> <em>{{ depositAmount }}</em>
        </accordion-list-item>
        <accordion-list-item>
          <p>주문 확정 기간</p>
          <p>{{ orderConfirmPeriod }}</p>
        </accordion-list-item>
        <accordion-list-item>
          <p>코치님 수수료 비율</p>
          <p>{{ percentFee }}</p>
        </accordion-list-item>
      </template>
    </accordion-list>
    <accordion-list use-accordion>
      <template #title>
        <h2>수익 정보</h2>
      </template>
      <template #list>
        <accordion-list-item>
          <p>(A)주문 금액</p>
          <p>{{ orderInfoTotal }}</p>
        </accordion-list-item>
        <ul>
          <accordion-list-item v-for="(product, i)  in orderInfoProducts" :key="i">
            <p>(A{{ `${i + 1}` }}){{ product.name }}</p>
            <p>{{ getPriceText(product.amount) }}</p>
          </accordion-list-item>
        </ul>
        <accordion-list-item>
          <p>(B)할인 금액</p>
          <p>{{ discountInfoTotal }}</p>
        </accordion-list-item>
        <!-- 할인금액 코치 쿠폰 금액은 현재 표기 안함 -->
        <accordion-list-item>
          <p>(C)차감 금액</p>
          <p>{{ getPriceText(subtractInfo.total) }}</p>
        </accordion-list-item>
        <ul>
          <accordion-list-item>
            <p>(C1)코치님 수수료</p>
            <p>{{ getPriceText(subtractInfo.fee) }}</p>
          </accordion-list-item>
          <accordion-list-item v-if="subtractInfo.dummyAmount > 0">
            <p>(C2)운영 테스트</p>
            <p>{{ getPriceText(subtractInfo.dummyAmount) }}</p>
          </accordion-list-item>
        </ul>
        <accordion-list-item>
          <p>(D)조정 금액</p>
          <p>{{ getPriceText(carryOverInfo.total) }}</p>
        </accordion-list-item>
        <ul>
          <accordion-list-item>
            <p>(D1)전월 이월</p>
            <p>{{ getPriceText(carryOverInfo.lastMonthAmount) }}</p>
          </accordion-list-item>
          <accordion-list-item>
            <p>(D2)익월 이월</p>
            <p>{{ getPriceText(carryOverInfo.nextMonthAmount) }}</p>
          </accordion-list-item>
        </ul>
      </template>
    </accordion-list>
    <accordion-list use-accordion>
      <template #title>
        <h2>세액 정보</h2>
      </template>
      <template #list>
        <accordion-list-item>
          <p>(E)원천징수세액</p>
          <p>{{ getPriceText(taxInfo.total) }}</p>
        </accordion-list-item>
        <ul>
          <accordion-list-item>
            <p>(E1)소득세</p>
            <p>{{ getPriceText(taxInfo.incomeTax) }}</p>
          </accordion-list-item>
          <accordion-list-item>
            <p>(E2)주민세</p>
            <p>{{ getPriceText(taxInfo.residenceTax) }}</p>
          </accordion-list-item>
        </ul>
      </template>
    </accordion-list>
  </settlement-detail-layout>
</template>
<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgAngle from '@shared/graphics/svg-angle.vue';
import { commaDecimal } from 'shared/utils/numberUtils';
import SettlementDetailLayout from '@/views/components/coaching/sales/SettlementDetailLayout.vue';
import AccordionList from '@/views/components/coaching/sales/AccordionList.vue';
import AccordionListItem from '@/views/components/coaching/sales/AccordionListItem.vue';
import StatusBadgeSettlement from '@/views/components/coaching/sales/StatusBadgeSettlement.vue';

export default {
  name: 'CoachSettlementDetail',
  components: { StatusBadgeSettlement, AccordionListItem, AccordionList, SettlementDetailLayout, ColorButton, SvgAngle },
  lexicon: 'coaching.settlementHistory',
  data: () => ({
    settlementInfo: /** @type{SettlementDetail} */ {},
  }),
  computed: {
    depositAmount() {
      return this.getPriceText(this.settlementInfo?.depositAmount);
    },
    settlementDatetime() {
      return this.$date(this.settlementInfo?.settlementDatetime, 'dash');
    },
    orderConfirmPeriod() {
      return [this.$date(this.settlementInfo?.orderConfirmPeriod?.start, 'monthDateDash'), this.$date(this.settlementInfo?.orderConfirmPeriod?.end, 'monthDateDash')].join('~');
    },
    percentFee() {
      return this.$t('_.percentFee', [this.settlementInfo?.percentFee]);
    },
    orderInfoTotal() {
      return this.getPriceText(this.settlementInfo?.orderInfo?.total ?? 0);
    },
    orderInfoProducts() {
      return this.settlementInfo?.orderInfo?.products ?? [];
    },
    discountInfoTotal() {
      return this.getPriceText(this.settlementInfo?.discountInfo?.total);
    },
    discountInfoCouponAmount() {
      return this.getPriceText(this.settlementInfo?.discountInfo?.couponAmount);
    },
    taxInfo() {
      return /** @type{SettlementDetail.taxInfo} */ this.settlementInfo?.taxInfo ?? {};
    },
    carryOverInfo() {
      return /** @type{SettlementDetail.carryOverInfo} */ this.settlementInfo?.carryOverInfo ?? {};
    },
    subtractInfo() {
      return /** @type{SettlementDetail.subtractInfo} */ this.settlementInfo?.subtractInfo ?? {};
    },
  },
  methods: {
    getPriceText(price) {
      const result = this.$t('coaching.PC_store_cost2', { amount: commaDecimal(Math.abs(price) ?? 0) });
      if (price < 0) return `(-) ${result}`;
      return result;
    },
    onBackward() {
      this.$router.push({ name: 'CoachSettlementHistory' });
    },
  },
  async mounted() {
    this.settlementInfo = await this.$services.coaching.getSettlementDetail(this.$route?.params?.settlementId);
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-settlement-detail] {
  .button-prev {.wh(40, 40);.t-r(90deg);.mb(28);}
  [accordion-list] {.max-w(908);.m(auto);}
}
</style>
