<template>
  <li accordion-list-item>
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: 'AccordionListItem',
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[accordion-list-item] { .flex; .space-between; .h(50); .max-h(50); .items-center; .pl(12);
  > *:nth-child(1) { .fs(14); .m(0); .p(0); }
  > *:nth-child(2) { .fs(14);}
  strong {.bold; .fs(18); }
  em { .bold; font-style: normal;}
}
</style>
