<script>
import StatusBadge from './StatusBadge.vue';

export default {
  extends: StatusBadge,
  computed: {
    badgeData() {
      return {
        COMPLETED: ['green', '입금 완료'],
        WAITING: ['blue', '입금 예정'],
        CARRY_OVER: ['yellow', '입금 이월'],
        EXCLUDED: ['red', '입금 제외']
      };
    },
    colorClass() {
      return this.badgeData[this.value]?.[0] ?? 'green';
    },
    statusText() {
      return this.badgeData[this.value]?.[1] ?? '-';
    }
  }
};
</script>